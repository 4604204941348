import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { Box, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';

const i18n = defineMessages({
	engagedLozengeLabel: {
		id: 'confluence-analytics.engagement-cell.engaged',
		defaultMessage: 'Engaged',
		description:
			'Label for the lozenge displayed in the engagement cell of the page views table when a user has met engagement criteria with a content piece.',
	},
	briefLozengeLabel: {
		id: 'confluence-analytics.engagement-cell.brief',
		defaultMessage: 'Brief',
		description:
			'Label for the lozenge displayed in the engagement cell of the page views table when a user has not met engagement criteria with a content piece.',
	},
	noDataTooltipContent: {
		id: 'confluence-analytics.viewers-table.column.engagement-cell.tooltip.no-data',
		defaultMessage:
			'No engagement data is available for this viewer. If they view this page again, their engagement status will be updated here.',
		description: 'Tooltip content for the "No data available" icon in the engagement cell.',
	},
});

const EngagementCellStyles = xcss({
	paddingLeft: 'space.500',
});

type EngagementCellProps = {
	isEngaged?: boolean | null;
};

export const EngagementCell = ({ isEngaged }: EngagementCellProps) => {
	if (isEngaged === null || isEngaged === undefined) {
		return (
			<Box xcss={EngagementCellStyles}>
				<Tooltip content={<FormattedMessage {...i18n.noDataTooltipContent} />} position="top">
					<Lozenge appearance="default">N/A</Lozenge>
				</Tooltip>
			</Box>
		);
	}

	return (
		<Box xcss={EngagementCellStyles} testId="engagement-cell">
			<Lozenge appearance={isEngaged ? 'inprogress' : 'moved'}>
				<FormattedMessage {...(isEngaged ? i18n.engagedLozengeLabel : i18n.briefLozengeLabel)} />
			</Lozenge>
		</Box>
	);
};
