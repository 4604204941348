import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl-next';
import { useCoordination, EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import {
	SpotlightManager,
	SpotlightTarget,
	SpotlightTransition,
	Spotlight,
} from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';
import { useCoordinationClient } from '@confluence/engagement-provider';
import { fg } from '@confluence/feature-gating';
import { useRouteActions } from '@confluence/route-manager';
import { useSpaceKey } from '@confluence/space-utils';
import { PAGE_ANALYTICS } from '@confluence/named-routes';

// Analytics Migration changeboarding components
// This experience consists of a pulsing button and spotlight on the analytics byline.
//
// FG: confluence_fe_analytics_migration_changeboarding
// EP message: https://engage-api.prod.atl-paas.net/#/messages/cc-analytics-migration-changeboarding
// Audience: https://data-portal.internal.atlassian.com/targeting/registry/2f9fabd0-5382-40f4-afd3-06a75a33ffee
const ENGAGEMENT_ID = 'cc-analytics-migration-changeboarding';
const LOCALSTORAGE_KEY = localStorageKeys.ANALYTICS_MIGRATION_CHANGEBOARDING_MESSAGE; // analytics-migration-changeboarding-message
const TARGET_ON_DOM = 'analytics-migration-changeboarding-spotlight';
const GASV3_EVENT_NAME = 'analyticsMigrationChangeboarding';
const MIN_VIEWERS_COUNT_REQUIREMENT = 10;
// Full page engagement
const TARGET_ON_DOM_FULL_PAGE = 'cc-analytics-migration-changeboarding-full-page'; // INAPP-3281: cc-analytics-migration-changeboarding-full-page

const i18n = defineMessages({
	title: {
		id: 'confluence-analytics.changeboarding.title',
		defaultMessage: 'Enhanced page analytics',
		description: 'Title of the spotlight',
	},
	content: {
		id: 'confluence-analytics.changeboarding.content',
		defaultMessage:
			'The full page analytics experience just got a major boost in usability and a fresh new look! Track your page’s performance and stakeholder views with greater ease than ever before.',
		description: 'The content of the spotlight which introduces the redesigned analytics page',
	},
	ctaButton: {
		id: 'confluence-analytics.changeboarding.ctaButton',
		defaultMessage: 'Check it out',
		description:
			'The text of the button in the spotlight which sends the user to the analytics page',
	},
	cancelButton: {
		id: 'confluence-analytics.changeboarding.cancelButton',
		defaultMessage: 'Dismiss',
		description:
			'The text of the button in the spotlight which closes the modal and dismisses the experience',
	},
});

const hasSeenLocal = () => localStorage.getItemAsBoolean(LOCALSTORAGE_KEY);
const markAsSeenLocal = () => localStorage.setItem(LOCALSTORAGE_KEY, true);
const isSSR = () => Boolean(process.env.REACT_SSR);

const useChangeboardingCoordination = ({ contentId }: { contentId: string }) => {
	const coordinationClient = useCoordinationClient();
	const [isDismissed, setDismissed] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouteActions();
	const spaceKey = useSpaceKey();

	const [hasPlatformAllowed, markAsSeenPlatform] = useCoordination(
		coordinationClient,
		ENGAGEMENT_ID,
	);

	const markAsSeen = useCallback(async () => {
		markAsSeenLocal();
		await markAsSeenPlatform();
	}, [markAsSeenPlatform]);

	// Content requirements which are not handled by EP:
	// 1- Content should be created/edited by me
	// 2- Content should have more than 10 viewers
	const contentQueryResponse = useQuery(
		gql`
			query MigrationChangeboardingAssetsContentQuery($contentId: ID!) {
				contentAnalyticsViewers(contentId: $contentId) {
					count
				}
				contentContributors(id: $contentId) {
					isCurrentUserContributor
				}
			}
		`,
		{
			variables: { contentId },
			errorPolicy: 'all',
			skip:
				hasSeenLocal() ||
				!hasPlatformAllowed ||
				// If the kill-switch FG is off, or we're in SSR, we shouldn't be reaching this line.
				// This additional checks are for safety.
				!fg('confluence_fe_analytics_migration_changeboarding') ||
				isSSR(),
		},
	);
	const viewersCount = contentQueryResponse.data?.contentAnalyticsViewers?.count || 0;
	const hasEnoughViewers = viewersCount >= MIN_VIEWERS_COUNT_REQUIREMENT;
	const isCurrentUserContributor =
		contentQueryResponse.data?.contentContributors?.isCurrentUserContributor || false;

	const isEligible =
		!isDismissed &&
		!hasSeenLocal() &&
		hasPlatformAllowed &&
		hasEnoughViewers &&
		isCurrentUserContributor &&
		// If the kill-switch FG is off, or we're in SSR, we shouldn't be reaching this line.
		// This additional checks are for safety.
		fg('confluence_fe_analytics_migration_changeboarding') &&
		!isSSR();

	// We only want the screen event fires once
	const hasScreenEventFired = useRef(false);
	useEffect(() => {
		if (isEligible && !hasScreenEventFired.current) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					action: 'viewed',
					name: GASV3_EVENT_NAME,
				},
			}).fire();
			hasScreenEventFired.current = true;
		}
	}, [createAnalyticsEvent, isEligible]);

	const handleDismiss = async (subjectId: string) => {
		await markAsSeen();
		setDismissed(true);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: subjectId,
				source: GASV3_EVENT_NAME,
			},
		}).fire();
	};

	const handleProceed = async (subjectId: string) => {
		await markAsSeen();
		push(
			PAGE_ANALYTICS.toUrl({
				spaceKey,
				contentId,
			}),
		);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: subjectId,
				source: GASV3_EVENT_NAME,
			},
		}).fire();
	};

	return { isEligible, handleDismiss, handleProceed };
};

const spotlightPulseContainerStyles = xcss({
	display: 'inline-block',
});

const MigrationChangeboardingBylinePulseInner = ({
	contentId,
	children,
}: {
	contentId: string;
	children: React.ReactNode;
}) => {
	const { formatMessage } = useIntl();
	const { isEligible, handleDismiss, handleProceed } = useChangeboardingCoordination({ contentId });

	if (!isEligible) {
		return <Fragment>{children}</Fragment>;
	}

	return (
		<Box xcss={spotlightPulseContainerStyles}>
			<SpotlightManager
				blanketIsTinted={false}
				onBlanketClicked={async () => {
					await handleDismiss('blanket');
				}}
			>
				<SpotlightTarget name={TARGET_ON_DOM}>
					<Box paddingInline="space.050">{children}</Box>
				</SpotlightTarget>
				<SpotlightTransition>
					<Spotlight
						actions={[
							{
								text: formatMessage(i18n.ctaButton),
								onClick: async () => {
									await handleProceed('cta-button');
								},
							},
							{
								text: formatMessage(i18n.cancelButton),
								appearance: 'subtle',
								onClick: async () => {
									await handleDismiss('cancel-button');
								},
							},
						]}
						heading={formatMessage(i18n.title)}
						key={`${TARGET_ON_DOM}-key`}
						target={TARGET_ON_DOM}
						targetRadius={3}
						targetOnClick={async () => {
							await handleProceed('target-button');
						}}
						dialogPlacement="right top"
						pulse
						shouldWatchTarget
					>
						<FormattedMessage {...i18n.content} />
					</Spotlight>
				</SpotlightTransition>
			</SpotlightManager>
		</Box>
	);
};

/*
 * PUBLIC COMPONENTS
 */
export const MigrationChangeboardingBylinePulse = ({
	contentId,
	children,
}: {
	contentId: string;
	children: React.ReactNode;
}) => {
	// To avoid unnecessary server calls, return early if
	// - the FG is off
	// - in SSR mode, or
	// - the user has already seen the engagement
	if (!fg('confluence_fe_analytics_migration_changeboarding') || isSSR() || hasSeenLocal()) {
		return <Fragment>{children}</Fragment>;
	}

	return (
		<MigrationChangeboardingBylinePulseInner contentId={contentId}>
			{children}
		</MigrationChangeboardingBylinePulseInner>
	);
};

export const MigrationChangeboardingFullPageSpotlight = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	if (!fg('confluence_fe_analytics_changeboarding_full_page')) {
		return <Fragment>{children}</Fragment>;
	}

	return (
		<SpotlightManager blanketIsTinted={false}>
			<SpotlightTarget name={TARGET_ON_DOM_FULL_PAGE}>{children}</SpotlightTarget>
			<EngagementSpotlight engagementId={TARGET_ON_DOM_FULL_PAGE} />
		</SpotlightManager>
	);
};
